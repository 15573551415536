<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle blackBack">
                {{ lang.account_statement }}
                    <div class="eportBtn">
                        <div>تصدير PDF</div>
                        <div>تصدير Excel</div>
                        <div>طباعة</div>
                    </div>
                    <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
                
            </div>
            <div class="cusModalBody">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="6" class="text-center backBlack">{{account_name}}</th>
                        </tr>
                        <tr>
                            <th class="text-center ">{{lang.date}}</th>
                            <th class="text-center ">رقم السند</th>
                            <th class="text-center ">{{lang.description}}</th>
                            <th class="text-center ">{{lang.depit}}</th>
                            <th class="text-center ">{{lang.credit}}</th>
                            <!-- <th class="text-center ">{{lang.balance}}</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tbrows" :key="index">
                            <td class="text-center" style="direction:ltr">{{item.receipt_date}}</td>
                            <td class="text-center" style="direction:ltr">{{item.voutcherid}}</td>
                            <td class="text-center" style="direction:ltr">{{item.pay_reson}}</td>
                            <td class="text-center" style="direction:ltr">{{item.depit}}</td>
                            <td class="text-center" style="direction:ltr">{{item.credit}}</td>
                            <!-- <td class="text-center" style="direction:ltr">{{item.balance}}</td> -->
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="3" class="backBlack">{{ lang.totals }}</th>
                            <th class="backGreen">{{ $RoundNums(allTotals.debit,2) }}</th>
                            <th class="backRed">{{ $RoundNums(allTotals.credit,2) }}</th>
                        </tr>
                        <tr>
                            <th colspan="6"> {{lang.balance}} : {{$RoundNums(allTotals.balance,2)}} </th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            showtable: false,
            tbrows: [],
            search: {
                sdate: '',
                edate: '',
            },
            classid: 0,
            account_name: ''
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        allTotals: function(){
            let t = {
                debit: 0,
                credit: 0,
                balance: 0,

            }
            for(let i=0;i<this.tbrows.length;i++){
                t.debit = parseFloat(t.debit) + parseFloat(this.tbrows[i].depit)
                t.credit = parseFloat(t.credit) + parseFloat(this.tbrows[i].credit)
            }
            t.balance = parseFloat(t.debit) - parseFloat(t.credit)
            return t;
        },
        btotals: function(){
            let t = 0;
            for(let i=0;i<this.tbrows.length;i++){
                t = parseFloat(t) + parseFloat(this.tbrows[i].balance)
            }
            t = this.$RoundNum(t);
            if(t < 0){
                return "("+parseFloat(t) * parseFloat(-1)+")";
            }else{
                return t;
            }
        },
    },
    methods: {
        closeTable(){
            this.showtable = false;
            this.tbrows = []
            this.search = {
                sdate: '',
                edate: '',
            }
            this.classid = 0
            this.account_name = ''
        },
        getReport(item) {
            this.account_name = item.name;
            const post = new FormData();
            post.append('type',"getPaymentReport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[elements]',JSON.stringify(item));
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.showtable = true
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.tbrows = res.results.data;
                }
            })
        }
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:80px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>

